import { Profile } from '../types/Profile';
import { socialLinks } from './socialLinks';

export const profile: Profile = {
  firstName: 'Cláudia',
  lastName: 'Freitas',
  position: 'Product Development Consultant @ Plural.com',
  summary: [
    'With a strong background in digital marketing, agile leadership, and product development, I excel at turning complex ideas into successful products.',
  ],
  avatar: {
    srcPath: 'profile/avatar_500x500.jpg',
    caption: 'Claudia Freitas',
  },
  location: {
    name: 'Palo Alto, California',
  },
  tags: [
    { name: 'Product Management' },
    { name: 'Project Management' },
    { name: 'Marketing' },
    { name: 'Copywriter' },
  ],
  socialLinks,
};
