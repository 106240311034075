import { socialLinkTypes } from '../types/SocialLink';
import type { SocialLink } from '../types/SocialLink';

/** Default username */
const DEFAULT_USERNAME = 'icfclaudia';

export const socialLinks: SocialLink[] = [
  {
    type: socialLinkTypes.linkedIn,
    url: `https://www.linkedin.com/in/${DEFAULT_USERNAME}`,
    secondary: false,
    caption: 'Cláudia Freitas on LinkedIn',
  },
  {
    type: socialLinkTypes.twitter,
    url: `https://twitter.com/${DEFAULT_USERNAME}`,
    secondary: false,
    caption: 'Cláudia Freitas on Twitter',
  },
  {
    type: socialLinkTypes.cal,
    url: `https://cal.com/${DEFAULT_USERNAME}`,
    secondary: false,
    caption: 'Cláudia Freitas on Calendly',
  },
  {
    type: socialLinkTypes.email,
    url: `mailto:${DEFAULT_USERNAME}@gmail.com?subject=[icfclaudia.com] Contact`,
    secondary: false,
    caption: 'Cláudia Freitas´ e-mail',
  },
  {
    type: socialLinkTypes.resume,
    url: 'https://drive.google.com/file/d/1B9RWnwnG_ATXo2oQFIumX3AwGrpBGQOc/view?usp=share_link',
    secondary: false,
    caption: 'Resume of Claudia Freitas',
  },
  {
    type: socialLinkTypes.fiverr,
    url: `https://fiverr.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Fiverr',
  },
  {
    type: socialLinkTypes.upwork,
    url: `https://upwork.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Upwork',
  },
  {
    type: socialLinkTypes.substack,
    url: `https://substack.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Substack',
  },
  {
    type: socialLinkTypes.medium,
    url: `https://medium.com/@${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Medium',
  },
  {
    type: socialLinkTypes.gitHub,
    url: `https://github.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on GitHub',
  },
  {
    type: socialLinkTypes.instagram,
    url: `https://www.instagram.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Instagram',
  },
  {
    type: socialLinkTypes.plural,
    url: `https://plural.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Plural',
  },
  {
    type: socialLinkTypes.youTube,
    url: `https://www.youtube.com/@${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on YouTube',
  },
  {
    type: socialLinkTypes.devTo,
    url: `https://dev.to/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on DevTo',
  },
  {
    type: socialLinkTypes.dribbble,
    url: `https://dribbble.com/${DEFAULT_USERNAME}`,
    secondary: true,
    caption: 'Cláudia Freitas on Dribbble',
  },
  {
    type: socialLinkTypes.stackOverflow,
    url: 'https://stackoverflow.com/users/20958444/claudia-freitas',
    secondary: true,
    caption: 'Cláudia Freitas on StackOverflow',
  },
];
